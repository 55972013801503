.root {
  padding: 0px;
  background-color: #eeeeee;
}


.root_inner {
  width: 60%;
  margin: 0px;
  -webkit-box-shadow: 0 0 30px grey;
  box-shadow: 0 0 30px grey;
  max-width: 800px;

}

.root_inner_mobile {
  width: 100%;
  margin-top: 0px;
  background-color: #eeeeee
}

.textbox {
  margin-left: 0px;
  justify-content: 'left';
  width: 50%;
  background-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

.selectuse {
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid rgb(56, 51, 51);
  width: 100%;
}

.features {
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid rgb(56, 51, 51);
  width: 100%;
}

.selectuse-radio {
  margin-left: 10px;
}

.features-listitem {
  width: 200px;
}

.features-slider {
  width: 100px;
}

.owlAbout {
  margin-top: -10px;
  color: black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.owlAbout p {
  text-align: left;
}

.owl {
  width: 15px;
  margin: 1px;
}

.owlSection {
  margin: 10px;
  margin-top: 20px;
  border-color: #bbbbbb;
  width: 100%;
}

.swearImage {
  width: 100%;
  margin: 5px;
}
