.netflixLink {
    margin: 1px;
    width: auto;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
}

.netflixLinkM {
    margin: 1px;
    padding-top: 10px;
    width: auto;
    color: black;
    background: white;
    text-align: center;
}

.filtering {
    margin: 1px;
    width: auto;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: lightyellow;
}


.filteringM {
    margin: 1px;
    padding-top: 10px;
    width: auto;
    color: black;
    background: lightyellow;
    text-align: center;
}

.tabletitle {
    color: #888888;
}
.profiles {
    margin: 1px;
    width: auto;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
}

.profilesM {
    margin: 1px;
    padding-top: 10px;
    width: auto;
    color: black;
    background: white;
    text-align: center;
}

.compare {
    padding-top: 40px;
    width: 100%;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    background: lightyellow;
}

.tablecompare {
    width: 60%;
    margin: 30px;
}
.tablecompareM {
    margin-top: 20px;
    width: 100%;
    margin-left: 2px;
    margin-right: 2px;
}




.netflixtext {
    margin: 20px;
    width: 50%;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.netflixtextM {
    margin: 2px;
    width: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}


.plusImage {
    width: 50%;
    margin: 5px;
    margin-top: 20px;
    margin-bottom: 30px;  
}


.plusImageM {
    width: 100%;
    margin-top: 20px;
}



.plusHeading {
    margin: 20px;
    font-size: 1.5em;
    /*line-height: 1rem;*/
    font-weight: bold;
    text-align: center;
}

.plusParagraph {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 20x;
    font-size: 1.2em;
    color: #777777;
    /*line-height: 1rem;*/
   
    text-align: center;
}

.plusnetflix {
    max-width: 800px;
    max-height: 400px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }


.plusnetflixM {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:90%;
}



.plusfiltering {
    max-width: 800px;
    max-height: 400px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }


.plusfilteringM {
    max-width: 800px;
    max-height: 300px;
    margin-left: auto;
    margin-right: auto;
   
}