/***********************************************************************
* 
* DESCRIPTION :
*   General APP CSS settings
*
* PUBLIC FUNCTIONS :
*      
* 
* AUTHOR(S) :
*   D.L. Johnson    START DATE : 1 Jan 2019
*
* Copyright 3Wise 2019. All rights reserved.
***********************************************************************/

.App {
  text-align: center;
  margin: 0 auto;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  /*background-color: #282c34; */
  background: #e0e0e0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* New style stuff */

html {
  height: 100%;
  margin: 0;
}

body {
  /*background: #0d0d0d; */
  background: #e0e0e0;
  color: white;
  font-family: sans-serif;
}

.lineText {
  width: 90%;
  text-align: center;
  border-bottom: 1px solid #aaaaaa;
  line-height: 0.1em;
  margin: 10px 0 20px;
  font-size: 14px;
  color: #555555;
}
.lineText span {
  background: #eeeeee;
  padding: 0 10px;
}

/*header*/
nav {
  /*background: black; */
  background: #e0e0e0;
  color: red;
  height: 65px;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 13px;
  box-sizing: border-box;
}

.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
  /*background-Color: black;*/
  background-color: #e0e0e0;
  color: white;
  width: 100%;
}

.Media-visible {
  visibility: visible;
}

.Media-invisible {
  display: none;
}

.blockedWords {
  min-width: auto;
  margin: 0 auto;
  padding: 20px;
  font-size: 18px;
  color: #e6e6e6;
  font-weight: 500;
}

.blockedWords2 {
  min-width: auto;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
  color: #e6e6e6;
  font-weight: 500;
  min-height: 350px;
}

.recommended {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.logo {
  float: left;
  font-size: 30px;
}

.logo a {
  color: #cc0000;
  text-decoration: none;
}

.logo a:hover {
  color: #e60000;
}

.search-bar {
  float: right;
}

.search-input {
  background: none;
  color: #cc0000;
  border: 1px solid #cc0000;
  outline: none;
  border-radius: 8px;
  padding: 5px;
  padding-left: 15px;
  width: 250px;
  height: 20px;
  font-weight: 600;
  margin-top: 5px;
  position: relative;
}

.search-values {
  background: #0d0d0d;
  width: 265px;
  border-radius: 5px;
  border: 2px solid #cc0000;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  position: absolute;
  margin-left: 2px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 0;
  z-index: 1;
}

.search-values ul {
  margin: 0;
  padding: 10px;
  list-style: none;
}

.search-values ul li {
  margin: 8px;
}

.search-values a {
  text-decoration: none;
  color: #e60000;
}

.search-values a:hover {
  text-decoration: underline;
  font-weight: 600;
}

.switchImg {
  position: absolute;
  top: 90%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.switchImg div {
  width: 10px;
  height: 10px;
  background: transparent;
  border: 1px solid #bbbbbb;
  border-radius: 50%;
  display: inline-block;
  margin: 20px;
  cursor: pointer;
}

.switchImg div.active {
  background: #404040;
}

/*lists of movies*/

.outside-lists-search {
  position: relative;
  margin-top: 40px;
  /*min-height: 290px; */
}

.lists-search-blank {
    
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  /*background-Color: black;*/
  background: #e0e0e0;
  color: black;
  width: 100%;
  height: 200px;
  margin-top: 40px;
  width: 100%;
}

.lists-layout {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.lists-scrollmenu {
  width: calc(100% - 90px);
}

.lists-button {
  width: 40px;
  font-size: 90%;
}

.lists-search {
  width: 100%;
  margin-top: 40px;
  min-height: 290px;
}

.lists {
  margin: 0px;
}

.lists:first-child {
  margin-top: 50px;
}

.lists h2 {
  padding-left: 50px;
  margin-bottom: 20px;
}

.scrollArea-main {
  width: 5000px;
  height: 300px;
}
.scrollArea-content {
  display: flex;
  alignItems: center;
  width: '5000px'
}


*:focus {
  outline: none;
}

/* Movie Dialog */

.dialog-MovieSummary {
  border: 10px solid white;
}

.menu-item {
  padding: 10px;
  margin: 5px 2px;
  user-select: none;
  cursor: pointer;
  border: none ;
  outline: none;
}

.menu-item-last {
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 60px;
  margin: 5px 2px;
  user-select: none;
  cursor: pointer;
  border: none;
  outline: none;
}

.menu-item-first-loader {
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 5px 2px;
  margin-left: -45px;
  user-select: none;
  cursor: pointer;
  border: none;
  outline: none;
}

.menu-item-wrapper.active {
  border: none;
}
.menu-item.active {
  border: none;
}

.horizontal-menu {
  height: 225px;
}

.movie-card {
  position: relative;
  /*width: 300px;
  height: 170px; */
  margin: 0px;
  vertical-align: middle;
  width: 150px;
  height: 225px;
  transition-property: width, height;
  transition-duration: 0.1s;
  transition-delay: 0.1s;
  outline: none;
  background: black;
  border: 12px solid white;
}

.blank-movie-card {
  position: relative;
  /*width: 300px;
  height: 170px; */
  margin: 0px;
  vertical-align: middle;
  width: 150px;
  height: 225px;
  outline: none;
  background: black;
  border: 12px solid white;
}

.movie-card::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /*background: linear-gradient(
    to bottom right ,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 80%,
    rgba(0, 0, 0, 1) 100%
  ) ;*/
  display: inline-block;
  
}



.movie-card-tv {
  position: relative;
  /*width: 300px;
  height: 170px; */
  margin: 0px;
  vertical-align: middle;
  width: 150px;
  height: 225px;
  transition-property: width, height;
  transition-duration: 0.1s;
  transition-delay: 0.1s;
  background: black;
  outline: none;
  border: 12px solid white;
}

.movie-card-tv:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 80%,
    rgba(0, 0, 0, 1) 100%
  )/*, linear-gradient(
    to bottom right ,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 80%,
    rgba(0, 0, 0, 1) 100%
  )*/ ;
  display: inline-block;
}

.cardimg {
  /*width: 300px;
  height: 170px; */
  width: 150px;
  height: 225px;
  object-fit: cover;
  vertical-align: middle;
  transition-property: width, height;
  transition-duration: 0.1s;
  transition-delay: 0.1s;
  user-select: none;
  outline: none;


}

.netflixIcon {
  position: absolute;
  bottom: 1%;
  right: 1%;
  height: 15px;
  /*background-color:  rgba(0,0,0, 0.3);*/
  
 
  z-index: 10;
  
}

.agerestriction {
  position: absolute;
  bottom: 1%;
  right: 2%;
  height: 13px;
  background-color: white;
}

.agerestrictionGB {
  position: absolute;
  bottom: -5%;
  right: 2%;
  height: 25px;
  
}

.agerestrictionUS {
  position: absolute;
  top: 1%;
  left: 1%;
  height: 13px;
  background-color: white;
}
.agerestrictionTVUS {
  position: absolute;
  top: 1%;
  left: 1%;
  height: 16px;
  background-color: white;
}

.agerestriction-div {
  position: absolute;
  border: 1px solid white;
  bottom: 1%;
  right: 2%;
  width: 40px;
  background-color: black;
  opacity: 0.9;
  font-size: 12px;
}

.typeicon {
  position: absolute;
  top: 0%;
  right: 1%;
  z-index: 2;
}

.faithicon {
  position: absolute;
  top: 1%;
  right: 25%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  
}

.certifiedicon {
  position: absolute;
  
  z-index: 2;
  position: absolute;
  bottom: -6%;
  right: 25%;
  height: 15px;
}

.blockedicon {
  position: absolute;
  opacity: 0.5;
  color: red;
  bottom: 20%;
  right: 20%;
  z-index: 2;
}

.halo {
  position: absolute;
  bottom: -5%;
  left: 5%;
  width: 24px;
}

.scorch {
  position: absolute;
  bottom: -5%;
  left: 5%;
  width: 36px;
}

.owl10 {
  position: absolute;
  top: 40px;
  right: 20px;
  width: 30px;
}

/*
.owl1 {
  position: absolute;
  bottom: -5%;
  left: 5px;
  width: 15px;

}

.owl2 {
  position: absolute;
  bottom: -5%;
  left: 22px;
  width: 15px;
  
}

.owl3 {
  position: absolute;
  bottom: -5%;
  left: 39px;
  width: 15px;
 
}

.owl4 {
  position: absolute;
  bottom: -5%;
  left: 41%;
  width: 15px;
  
}

.owl5 {
  position: absolute;
  bottom: -5%;
  left: 53%;
  width: 15px;

}

.owl6 {
  position: absolute;
  bottom: -5%;
  left: 65%;
  width: 15px;
 
}
*/

.owl1 {
  position: absolute;
  bottom: -5%;
  left: 2px;
  width: 15px;
}

.owl2 {
  position: absolute;
  bottom: -5%;
  left: 19px;
  width: 15px;
}

.owl3 {
  position: absolute;
  bottom: -5%;
  left: 36px;
  width: 15px;
}

.owl4 {
  position: absolute;
  bottom: -5%;
  left: 53px;
  width: 15px;
}

.owl5 {
  position: absolute;
  bottom: -5%;
  left: 70px;
  width: 15px;
}




.movie-card-tv .movie-title,
.movie-card .movie-title {
  position: absolute;
  top: 0%;
  text-align: left;
  width: 95%;
  white-space: initial;
  color: #fff;
  visibility: hidden;
  word-wrap: break-word;

  margin-left: 10px;
  margin-right: 20px;
  transition-delay: 0.1s;
}

.movie-card-tv .movie-title h4,
.movie-card .movie-title h4 {
  color: #fff;
  margin-top: 25px;
  margin-left: 0px;
  margin-bottom: -5px;
  margin-right: 3px;

}



.movie-card-tv .movie-title button,
.movie-card .movie-title button {
  margin-top: 15px;
  background: #f7440e;
}

.movie-card-tv figcaption h3,
.movie-card figcaption h3 {
  color: #fff;
  position: relative;
  text-align: center;
  /*top: -500px; */
  top: -200px;
  width: 100%;
  visibility: hidden;
  vertical-align: middle;
  transition-delay: 0.1s;
}

.movie-card-tv:focus,
.movie-card-tv:hover:not(.no-hover),
.movie-card:focus,
.movie-card:hover:not(.no-hover) {
  /*width: 330px;
  height: 190px; */
  width: 200px;
  height: 300px;
  vertical-align: middle;
  transition-property: width, height;
  transition-duration: 0.6s;
  transition-delay: 0.4s;
  z-index: 1;
  border: 12px solid #F9CC33;
}


.movie-card-tv:focus .movie-title,
.movie-card-tv:hover:not(.no-hover) .movie-title,
.movie-card:focus .movie-title,
.movie-card:hover:not(.no-hover) .movie-title {
  /*visibility: visible;
  opacity: 1; */
  /*transform: translateY(300px); */
  color: #fff;
  visibility: visible;
  /*transform: translateY(300px); */
  transition-delay: 1s;
  z-index: 1;
}

.movie-card-tv:focus figcaption h3,
.movie-card-tv:hover:not(.no-hover) figcaption h3,
.movie-card:focus figcaption h3,
.movie-card:hover:not(.no-hover) figcaption h3 {
  /*visibility: visible;
  opacity: 1; */
  /*transform: translateY(300px); */
  visibility: visible;
  /*transform: translateY(300px); */
  transition-delay: 1s;
  z-index: 1;
}
.movie-card-tv:focus .cardimg,
.movie-card-tv:hover:not(.no-hover) .cardimg,
.movie-card:focus .cardimg,
.movie-card:hover:not(.no-hover) .cardimg {
  /*width: 330px;
  height: 190px; */
  width: 200px;
  height: 300px;
  vertical-align: middle;
  background-color: black;
  opacity: 0.3;
  transition-property: width, height;
  transition-duration: 0.6s;
  transition-delay: 0.4s;
  z-index: 1;
}

/* 
.movie-card-tv:focus .owl1,
.movie-card-tv:hover .owl1,
.movie-card:focus .owl1,
.movie-card:hover .owl1 {
  position: absolute;
  bottom: -5%;
  left: 5px;
  width: 15px;

}
.movie-card-tv:focus .owl2,
.movie-card-tv:hover .owl2,
.movie-card:focus .owl2,
.movie-card:hover .owl2 {
  position: absolute;
  bottom: -5%;
  left: 22px;
  width: 15px;

}
.movie-card-tv:focus .owl3,
.movie-card-tv:hover .owl3,
.movie-card:focus .owl3,
.movie-card:hover .owl3 {
  position: absolute;
  bottom: -5%;
  left: 39px;
  width: 15px;

}

.movie-card-tv:focus .owl4, 
.movie-card-tv:hover .owl4,
.movie-card:focus .owl4, 
.movie-card:hover .owl4 {
  position: absolute;
  bottom: -5%;
  left: 41%;
  width: 15px;
  
}

.movie-card-tv:focus .owl5,
.movie-card-tv:hover .owl5,
.movie-card:focus .owl5,
.movie-card:hover .owl5 {
  position: absolute;
  bottom: -5%;
  left: 53%;
  width: 15px;
 
}

*/

.movie-card-tv:focus .owl1,
.movie-card-tv:hover:not(.no-hover) .owl1,
.movie-card:focus .owl1,
.movie-card:hover:not(.no-hover) .owl1 {
  position: absolute;
  bottom: -5%;
  left: 2px;
  width: 15px;
  z-index: 1;
}
.movie-card-tv:focus .owl2,
.movie-card-tv:hover:not(.no-hover) .owl2,
.movie-card:focus .owl2,
.movie-card:hover:not(.no-hover) .owl2 {
  position: absolute;
  bottom: -5%;
  left: 19px;
  width: 15px;
  z-index: 1;
}
.movie-card-tv:focus .owl3,
.movie-card-tv:hover:not(.no-hover) .owl3,
.movie-card:focus .owl3,
.movie-card:hover:not(.no-hover) .owl3 {
  position: absolute;
  bottom: -5%;
  left: 36px;
  width: 15px;
  z-index: 1;
}

.movie-card-tv:focus .owl4,
.movie-card-tv:hover:not(.no-hover) .owl4,
.movie-card:focus .owl4,
.movie-card:hover:not(.no-hover) .owl4 {
  position: absolute;
  bottom: -5%;
  left: 53px;
  width: 15px;
  z-index: 1;
}

.movie-card-tv:focus .owl5,
.movie-card-tv:hover:not(.no-hover) .owl5,
.movie-card:focus .owl5,
.movie-card:hover:not(.no-hover) .owl5 {
  position: absolute;
  bottom: -5%;
  left: 70px;
  width: 15px;
  z-index: 1;
}

.movie-card-tv:focus .halo,
.movie-card-tv:hover:not(.no-hover) .halo,
.movie-card:focus .halo,
.movie-card:hover:not(.no-hover) .halo {
  position: absolute;
  bottom: -5%;
  left: 5%;
  width: 24px;
}

.movie-card-tv:focus .scorch,
.movie-card-tv:hover:not(.no-hover) .scorch,
.movie-card:focus .scorch,
.movie-card:hover:not(.no-hover) .scorch {
  position: absolute;
  bottom: -5%;
  left: 5%;
  width: 36px;
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}

/*
.scroll-menu-arrow--disabled {
    visibility: hidden; 
} */

.arrow-prev {
  color: red;
  font-size: 40px;
}

.arrow-next {
  color: red;
  font-size: 40px;
}

/* movie info */

.content {
  min-width: 800px;
  max-width: 800px;
  margin-top: 10px;
  /*ont-size: 18px;(/
  /*font-weight: 500;*/

}

.content h1 {
  text-align: left;
  margin: 0px;
  margin-top: 0px;
  font-size: 25px;
  color: #222222;
}

.content button {
  color: #222222;
  background-color: #cccccc;
}

.content button:hover {
  background-color: #999999;
}

.content-mobile {
  margin-top: 12px;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
}

.content-mobile h1 {
  text-align: left;
  margin: 10px;
  margin-top: 10px;
  font-size: 20px;
  color: #222222;
}

.content-mobile button {
  color: #222222;
  background-color: #cccccc;
}

.content-mobile button:hover {
  background-color: #999999;
}

.content-mobile,
.content p {
  line-height: 19px;
}

.video-outer {
  max-width: auto;
}

.video {
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
  width: 60%;
  height: 300px;
  float: left;

  -webkit-box-shadow: 0 0 30px grey;
  box-shadow: 0 0 30px grey;
}

.video iframe {
  border: 0;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.video-wide {
  position: relative;
  padding-bottom: 56.25%;

  padding-top: 30px;
  height: 0;
  overflow: hidden;

  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;

  -webkit-box-shadow: 0 0 30px grey;
  box-shadow: 0 0 30px grey;
}

.video-wide iframe {
  position: absolute;
  border: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overview-container {
  margin-left: 10px;
  margin-bottom: 20px;
  width: 35%;
  float: left;
  color: #222222;
}

.overview-container-wide {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  width: 70%;
  color: #222222;
}

.overview-container-description {
  margin-left: 10px;
  clear: left;
  width: 95%;
  position: relative;
  color: #222222;
}

.greyed {
  /*color: #9c9c9c; */
  color: black;
  font-weight: bold;
}

.year-run-vote span {
  font-size: 14px;
  padding-right: 6px;
}

.run,
.vote {
  border-left: 1px solid white;
  padding-left: 6px;
}

i {
  color: rgba(80, 80, 80, 1);
}

.SeriesEpisodes {
  display: flex;
  width: 300px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.seriesList {
  margin: 10px;
}

.episodeList {
  margin: 10px;
}

.movie-background {
  margin-left: 10px;
  margin-right: 10px;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 20px;

  -webkit-box-shadow: 0 0 50px grey;
  box-shadow: 0 0 50px grey;

  background-color: rgba(240, 240, 240, 0.8);
}

.bgImage {
  height: 400px;
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: white;
  z-index: 1;
}

.signin-frame {
  width: 50%;
  margin: 25%;
}
.popularInfo {
  position: absolute;
  top: 10%;
  left: 40px;
  width: 80%;
}

.popularInfo_mobile {
  position: absolute;
  top: 10%;
  left: 10px;
  right: 10px;
  width: 95%;
}

.popularInfo h1,
.popularInfo_mobile h1 {
  margin: 0;
  margin-bottom: 30px;
}

.popularInfo button,
.popularInfo_mobile button {
  background: #f7440e;
}

.popularInfo_mobile button:hover,
.popularInfo button:hover {
  background: #ff5b29;
}

@media only screen and (max-width: 900px) {
  .back-height {
    height: auto;
  }
  /*header*/
  .bgImage {
    height: 400px;
  }

  .header-overview {
    display: none;
  }

  .timeline-overview {
    margin: 10px;
    background: grey;
  }

  .popularInfo_mobile,
  .popularInfo {
    text-align: center;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 90%;
  }

  .switchImg div {
    margin: 7px;
  }

  /*lists of movies*/

  .lists-search {
    margin-top: 40px;
    min-height: 220px;
  }

  

  .lists {
    margin: 10px;
  }

  .lists h2 {
    margin-bottom: 0;
  }

  .horizontal-scroll {
    overflow-y: scroll;
    overflow-x: scroll;
    overflow: scroll;
    z-index: 0;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
  }
  .arrow-prev {
    font-size: 20px;
  }

  .arrow-next {
    font-size: 20px;
  }

  .scroll-menu-arrow {
    padding: 10px;
  }

  /*binfo*/

  /*
  .content {
    width: 90%;
    font-size: 90%;
  }

  .content h1 {
    margin: 30px 0;
  }

  .blockedWords {
    width: 95%;
  } */
}

@media only screen and (max-width: 690px) {
  nav {
    height: 100px;
  }
  .logo,
  .search-bar {
    float: none;
  }

  .logo {
    text-align: center;
  }

  .search-input {
    width: 95%;
  }

  .search-values {
    width: 87%;
  }
  .bgImage {
    height: 400px;
    background-size: cover;
  }
  .popularInfo_mobile button,
  .popularInfo button {
    margin: 0;
  }

  .lists h2 {
    padding-left: 5px;
    margin-bottom: 10px;
  }

  .movie-title {
    font-size: 14px;
    
  }
}

@media only screen and (max-width: 450px) {
}

@media only screen and (max-width: 350px) {
  .back-height {
    height: auto;
  }

  .popularInfo_mobile,
  .popularInfo {
    text-align: center;
    top: 40%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 90%;
  }
  .popularInfo_mobile button,
  .popularInfo button {
    height: 40px;
    width: 100px;
    font-size: 90%;
    margin-top: 10px;
  }

  /* list of movies*/

  .horizontal-menu {
    height: 190px;
  }
}

@media only screen and (max-height: 800px) {
  .back-height {
    height: auto;
  }
}
