.moviesLoading {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*background-Color: black;*/
    background: #e0e0e0;
    color: black;
    width: 100%;
    height: 400px;
  }

  .loadingText {
      margin: 10px;
  }