.screenStyle {
  position: fixed;
  top: 5em;
  left: 2%;
  right: 2%;
  bottom: 2%;
}

.button {
  width: 40px;
  font-size: 90%;
  margin: 5px;
}

/*
.lists-layout {

  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
}*/

.tiledMovies {
  padding-top:30px;
  padding-left:20px;
  padding-right:20px;
  padding-bottom: 30px;
  margin-top: -30px;
  margin-bottom: -30px;
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  overflow: hidden;
  z-index: 200;
}

.moviesLoading {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*background-Color: black;*/
  background: #e0e0e0;
  color: black;
  width: 100%;
  height: 200px;
}

.loadingText {
    margin: 10px;
}
