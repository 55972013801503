.TopLoaderShow {
  z-index: 99;
  top: 0;
  bottom: 0%;
  left: 0;
  right: 0%;
  background-color: #868686;
  position: absolute;
  display: block;
}

.TopLoaderHide {
  z-index: 99;
  top: 0;
  bottom: 0%;
  left: 0;
  right: 0%;
  background-color: #868686;
  position: absolute;
  display: none;
}

.TopLoadSpinner {
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -10%);
  width: 100px;
}

.infobar {
  background-color: lightyellow;
}

.owlAnimate{
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -150px;
  margin-top: -150px;
  width: 300px;

}

.TheAppHide {
  display: none;
}

/*.TheAppOuter {
  overflow: hidden
}*/

.TheAppShow {
  display: block;
  
}

.userIcon {
  padding-top: 0px;
  margin-bottom: 0px;
  /*background: #ffffff;*/
}



.Content {
  margin-top: 72px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.menuButton {
  margin-right: 0px;
  margin-left: 0px;
  
}

.toolbar {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-left: 0px;
  align-items: flex-end;
  /*background-color: #868686*/
}


.toolbar_mobile {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
  align-items: center;
}

.verticalcenter {
  padding-top: 5px;
  height: 40px;
  width: 60px;
  vertical-align: middle;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.region {
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: '#888888';
  background-color: lightyellow
}

.region p {
  margin-left: 10px;
  font-size: 12px;
}

.regionText {
  font-size: 12px;
  text-align: center;
}

.regionPopup {
  width: 180px;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: -15px;
}


.flagimage {
  margin-left: 0px;
  margin-right: 10px;
  height: 25px;
}

.flagandtext {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.App-link {
  color: #61dafb;
}

.bannerShow {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #444444;
  margin: 0px auto;
  display: block;
}

.bannerHide {
  background: #eeeeee;
  height: 0;
  display: none;
}



.search-block {
  width: 100%;
  background: #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #444444;
  padding-top: 20px;
  padding-bottom:20px;
}

.search-blockHide {
  height: 0;
  display: none;
}

.search-block p {
  width: 100%;
  margin: 5px auto;
  font-size: 15px;
  font-style: italic;
  text-align: center;
}
.search-block h3 {
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
}


.topMenu {
  width: 100%;
  background: #eeeeee;
}

.prosymbol {
  position: relative;
  top: -60px;
  left: 35px;
  width: 22px;
  /*margin-left: -20px;*/
  background-color: rgb(254,201,0);
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
  padding-top: 2px;
  font-size: 12px;
}

.plussymbol {
  position: relative;
  top: -60px;
  left: 35px;
  width: 25px;
  /*margin-left: -20px;*/
  background-color: rgb(254,201,0);
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
  padding-top: 2px;
  font-size: 12px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
