/***********************************************************************
* 
* DESCRIPTION :
*   SingleMovieInfinite CSS settings
*
* PUBLIC FUNCTIONS :
*      
* 
* AUTHOR(S) :
*   D.L. Johnson    START DATE : 1 Jan 2019
*
* Copyright 3Wise 2019. All rights reserved.
***********************************************************************/

.Imovie-card {
  position: relative;
  /*width: 300px;
  height: 170px; */
  margin: 0px;
  vertical-align: top;
  /*width: 150px;
  height: 225px;*/
  transition-property: transform;
  transition-duration:  0.1s;
  transition-delay:  0.1s;
  outline: none;
  background: black;
  border: 12px solid white;
}

.Imovie-card::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /*background: linear-gradient(
    to bottom right ,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 80%,
    rgba(0, 0, 0, 1) 100%
  ) ;*/
  display: inline-block;
}


.Imovie-card-tv {
  position: relative;
  /*width: 300px;
  height: 170px; */
  margin: 0px;
  vertical-align: middle;
  width: 150px;
  height: 225px;
  /*transition-property: width, height; */
  transition-property: transform;
  transition-duration:  0.1s;
  transition-delay:  0.1s;
 
  background: black;
  outline: none;
  border: 12px solid white;
}

.Imovie-card-tv:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 80%,
    rgba(0, 0, 0, 1) 100%
  )/*, linear-gradient(
    to bottom right ,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 80%,
    rgba(0, 0, 0, 1) 100%
  )*/ ;
  display: inline-block;
}

.Icardimg {
  /*width: 300px;
  height: 170px; */
  width: 150px;
  height: 225px;
  object-fit: cover;
  vertical-align: top;
  /*transition-property: width, height;
  transition-duration: 0.1s;
  transition-delay: 0.1s;*/
  /*transition-property: transform;
  transition-duration:  0.1s;
  transition-delay:  0.1s;*/
  user-select: none;
  outline: none;
}

.InetflixIcon {
  position: absolute;
  bottom: 1%;
  right: 1%;
  height: 15px;
  /*background-color:  rgba(0,0,0, 0.3);*/
  z-index: 10;
  
}

.Iagerestriction {
  position: absolute;
  bottom: 1%;
  right: 2%;
  height: 13px;
  background-color: white;
}

.IagerestrictionGB {
  position: absolute;
  bottom: -5%;
  right: 2%;
  height: 25px;
  transition-property: height;
  transition-duration:  0.1s;
  transition-delay:  0.1s;
}


.IagerestrictionUS {
  position: absolute;
  top: 1%;
  left: 1%;
  height: 13px;
  background-color: white;
  transition-property: height;
  transition-duration:  0.1s;
  transition-delay:  0.1s;
}



.IagerestrictionTVUS {
  position: absolute;
  top: 1%;
  left: 1%;
  height: 16px;
  background-color: white;
  transition-property: transform;
  transition-duration:  0.1s;
  transition-delay:  0.1s;
}



.Iagerestriction-div {
  position: absolute;
  border: 1px solid white;
  bottom: 1%;
  right: 2%;
  width: 40px;
  background-color: black;
  opacity: 0.9;
  font-size: 12px;
}


.Itypeicon {
  position: absolute;
  top: 0%;
  right: 1%;
  z-index: 2;
}

.Ifaithicon {
  position: absolute;
  top: 1%;
  right: 25%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
}

.Icertifiedicon {
  position: absolute;
  
  z-index: 2;
  position: absolute;
  bottom: -6%;
  right: 25%;
  height: 15px;
}

.Iblockedicon {
  position: absolute;
  opacity: 0.5;
  color: red;
  bottom: 20%;
  right: 20%;
  z-index: 2;
}



.Iowl1 {
  position: absolute;
  bottom: -5%;
  left: 2px;
  width: 15px;
}

.Iowl2 {
  position: absolute;
  bottom: -5%;
  left: 19px;
  width: 15px;
}

.Iowl3 {
  position: absolute;
  bottom: -5%;
  left: 36px;
  width: 15px;
}

.Iowl4 {
  position: absolute;
  bottom: -5%;
  left: 53px;
  width: 15px;
}

.Iowl5 {
  position: absolute;
  bottom: -5%;
  left: 70px;
  width: 15px;
}

.Imovie-card-tv .movie-title,
.Imovie-card .movie-title {
  position: absolute;
  top: 0%;
  text-align: left;
  width: 95%;
  white-space: initial;
  color: #fff;
  visibility: hidden;
  word-wrap: break-word;

  margin-left: 10px;
  margin-right: 20px;
  
}

.Imovie-card-tv .movie-title h4,
.Imovie-card .movie-title h4 {
  color: #fff;
  margin-top: 10px;
  margin-left: 0px;
  margin-bottom: -5px;
  margin-right: 3px;

}

.Imovie-card-tv .movie-title button,
.Imovie-card .movie-title button {
  margin-top: 5px;
  margin-left: -10px;
  background: #f7440e;
  transform: scale(0.75);

}

.Imovie-card-tv figcaption h3,
.Imovie-card figcaption h3 {
  color: #fff;
  position: relative;
  text-align: center;
  /*top: -500px; */
  top: -200px;
  width: 100%;
  visibility: hidden;
  vertical-align: middle;
  transition-delay: 0.1s;
}


.Imovie-card-tv:focus,
.Imovie-card-tv:hover:not(.no-hover),
.Imovie-card:focus,
.Imovie-card:hover:not(.no-hover) {
  transform: scale(1.33);
  /*width: 200px;
  height: 300px;*/

  /*vertical-align: -25px;*/
  /*transition-property: width, height;*/
  /*transition-duration: 0.6s;
  transition-delay: 0.4s;*/
  z-index: 100;
  transition-property: transform;
  transition-duration:  0.6s;
  transition-delay:  0.4s;
  border: 12px solid #F9CC33;
  

}


.Imovie-card-tv:focus .movie-title,
.Imovie-card-tv:hover:not(.no-hover) .movie-title,
.Imovie-card:focus .movie-title,
.Imovie-card:hover:not(.no-hover) .movie-title {
  /*visibility: visible;
  opacity: 1; */
  /*transform: translateY(300px); */
  visibility: visible;
  line-height: 100%;
  margin-top: 8px;
  font-size: 12px;
  /*transform: translateY(300px); */
  transition-delay: 1s;
  z-index: 1;
}



.Imovie-card-tv:focus figcaption h3,
.Imovie-card-tv:hover:not(.no-hover) figcaption h3,
.Imovie-card:focus figcaption h3,
.Imovie-card:hover:not(.no-hover) figcaption h3 {
  /*visibility: visible;
  opacity: 1; */
  /*transform: translateY(300px); */
  visibility: visible;
  transform: scale(0.75);
  /*transform: translateY(300px); */
  transition-delay: 1s;
  z-index: 1;
}
.Imovie-card-tv:focus .Icardimg,
.Imovie-card-tv:hover:not(.no-hover) .Icardimg,
.Imovie-card:focus .Icardimg,
.Imovie-card:hover:not(.no-hover) .Icardimg {
  /*width: 330px;
  height: 190px; */
  /*transform: scale(1.33);
  transition: transform 0.6s;*/
 /* width: 200px;
  height: 300px;*/
  vertical-align: middle;
  background-color: black;
  opacity: 0.3;
  /*transition-property: width, height;*/
  /*transition-property: transform;
  transition-duration:  0.6s;
  transition-delay:  0.4s;*/
  z-index: 1;
}



.Imovie-card-tv:focus .IagerestrictionUS,
.Imovie-card-tv:hover:not(.no-hover) .IagerestrictionUS,
.Imovie-card:focus .IagerestrictionUS,
.Imovie-card:hover:not(.no-hover) .IagerestrictionUS 
{
  height: 10px;
  transition-property: height;
  transition-duration:   0.6s;
  transition-delay:   0.4s;
}

.Imovie-card-tv:focus .IagerestrictionTVUS,
.Imovie-card-tv:hover:not(.no-hover) .IagerestrictionTVUS,
.Imovie-card:focus .IagerestrictionTVUS,
.Imovie-card:hover:not(.no-hover) .IagerestrictionTVUS 
{
  height: 12px;
  transition-property: height;
  transition-duration:  0.6s;
  transition-delay:  0.4s;
}


.Imovie-card-tv:focus .IagerestrictionGB,
.Imovie-card-tv:hover:not(.no-hover) .IagerestrictionGB,
.Imovie-card:focus .IagerestrictionGB,
.Imovie-card:hover:not(.no-hover) .IagerestrictionGB 
{
  height: 19px;
  transition-property: height;
  transition-duration:  0.6s;
  transition-delay:  0.4s;
}




