.root {
  padding: 0px;
}

.root_inner {
  width: 60%;
  margin: 40px;
  -webkit-box-shadow: 0 0 30px grey;
  box-shadow: 0 0 30px grey;
  max-width: 800px;
}

.root_inner_mobile {
  width: 100%;
  margin-top: 40px;
}

.container {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
}
.container p {
  color: #222222;
}

.question_container {
  display: flex;
  align-items: center;
  margin-left: 0px;
  width: 100%;
  height: 40px;
}

.toggle_container {
  width: 80px;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 10px;
  background-color: transparent;
}

.toggle_button {
  min-width: 30px;
  margin: 100px;
  border-radius: 20px;
}

.addwords {
  margin-top: 20px;
  color: rgb(255, 81, 0);
  border-color: rgb(255, 81, 0);
  width: 100%;
}

.delwords {
  margin-top: 20px;
  color: rgb(255, 81, 0);
  border-color: rgb(255, 81, 0);
  width: 100%;
}

.textBox {
  width: 80%;
  justify-content: left;
  background-color: transparent;
}

.textBox_mobile {
  margin-left: 0px;
  justify-content: left;
  width: 100%;
  background-color: transparent;
}

.textSelect {
  background-color: transparent;
}

.textSelect_mobile {
  background-color: transparent;
}

.textField {
  margin-top: -10px;
  margin-left: 5px;
  margin-right: 10px;
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

.selectuse {
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid rgb(56, 51, 51);
  width: 100%;
}

.features {
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid rgb(56, 51, 51);
  width: 100%;
}

.selectuse-radio {
  margin-left: 10px;
}

.features-listitem {
  width: 200px;
}

.features-slider {
  width: 100px;
}

.scroll-container {
  height: 300px;

  font-size: 20px;
  border: 1px solid grey;
  position: relative;
  margin-top: 10px;
  margin-bottom: 20px;
  overflow: scroll;
}
