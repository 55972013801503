.foot {
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;

  justify-content: space-around;

  margin: 60px auto;

  color: #222222;
}

.foot_copyright {
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;

  justify-content: space-around;
  margin: 10px auto;

  color: #222222;
}

.foot p {
  margin: 5px auto;
  text-align: center;
}
