/* 
.MuteButton {
    background-color: #222222;
    border: solid 1px transparent;
    border-radius: 4px;
    padding: 50px 40px;
    font-size: 20px;
    margin: 20px;
    cursor: pointer;
}

.root {
    overflow: 'hidden';
    overflow-y : 'hidden';
   
    border: '10px solid black'
  }

.labelA {
color: 'black';
}



.ShowMuteButton {
    background-color: 'rgb(254,201,0)';
    padding: 10px 40px;
    margin-bottom: '20px';
    text-transform: 'none';
}

.ShowMuteButton:hover {
  background-color: #ffffff;
} */

.markSection {
  
  height: 50px;
  background: 'rgb(224,224,224)';
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #444444;
  margin-top: 0px;
  margin-bottom: 0px;
  
}

.markSectionTop {
  background: 'rgb(224,224,224)';
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #444444;
  margin-top: 0px;
  margin-bottom: 0px;
  z-index: 1;
  position: absolute;
}

.owlAboutMD {
  margin-left: 0px;
  width: auto;
  color: black;
  cursor: pointer;
}

.owlAboutPop {
  margin: 1px;
  width: auto;
  color: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.owl {
  width: 15px;
  margin: 1px;
}

.ageMD {
  width: 50px;
  color: black;
}

.agerestrictionMD {
  width: 30px;
  margin: 1px;
}

.titleMD {
  max-width: 450px;
  text-align: center;
}

.bottomButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.imdbSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}