.plusroot {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.plusheader {
    max-width: 700px;
    margin: 1px;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
}


.plusfeatures {
    margin: 10px;
    max-width: 700px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.pluslearnmore {
    margin: 20px;
}



.singlefeature {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 200px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #cccccc;
}

.tickfeature {
    width: 20px;
}

.ticktext {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 20x;
    font-size: 1.2em;
    color:#555555;
    /*line-height: 1rem;*/
   
    text-align: center;
}

.pluspayoptions {
    margin: 10px;
    max-width: 700px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.payoption {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 0px;
    padding-right: 0px;
    max-width:300px;
    min-width: 300px;
    width: auto;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #444444; 
}
.payoptionHighlight {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 0px;
    padding-right: 0px;
    max-width:300px;
    min-width: 300px;
    width: auto;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid#0400ff;
}

.payoption,
.payoptionHighlight:hover {
    cursor: pointer;
}

.plusCostHeading {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 1.2em;
    color:#555555;
    /*line-height: 1rem;*/
   
    text-align: center;
}

.plusCostLine {
    margin-left: 5px;
    margin-right: 5px;
    flex-direction:row;
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.plusCost {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 2em;
    font-weight: bold;
}

.plusPer {
    font-size: 1.2em;
}

.plusCostMonth {
    flex-direction:row;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.plusSubtext {
    font-size: 1em;
    color:#777777;
}

.plusSubtextHighlight {
    margin-right: 10px;
    font-size: 1em;
    color:#f23e7b;
}

.plusHeading {
    margin: 20px;
    font-size: 1.5em;
    /*line-height: 1rem;*/
    font-weight: bold;
    text-align: center;
}

.plusParagraph {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 20x;
    font-size: 1.2em;
    color:#555555;
    /*line-height: 1rem;*/
   
    text-align: center;
}




.orderPaypalOuter {
    flex-direction:row;
    display: flex;
    align-items: center;
    justify-content: center;
  
}

.orderPaypalInner {
    width: 250px;
}


.orderSummary {
    width: 400px;
    margin-top: 20px;
    padding: 10px;
    color: #444444;
    

    background: #cccccc;
    border: 1px solid #444444; 
    border-radius: 8px;
    

}

.orderSummary h1 {
    font-weight: normal;
}

.orderSummary p {
    font-weight: normal;
    
}

.orderCostLine {
    flex-direction:row;
    display: flex;
    align-items: center;
    justify-content: space-between;
}