.Stopwatch-container {
  width: auto;
  align-content: center;
}

.Stopwatch-display {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Arrows {
  font-size: 40px;
  padding: -50px;
}

.Stopwatch-numbers {
  color: #222222;
  margin: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.Stopwatch-numbers-mobile {
  color: #222222;
  margin: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.Stopwatch-timer {
  padding: 20px 0;
  margin-left: 10px;
  font-size: 30px;
  display: flex;
  justify-content: center;
}

.NextMute {
  color: #222222;
  margin: 10px;
  display: flex;
  justify-content: center;
}

.NextMute-mobile {
  color: #222222;
  margin: 10px;
  font-size: 15px;
  display: flex;
  justify-content: center;
}

.Stopwatch-nextword {
  font-size: 20px;
  border: 3px solid green;
}

.Stopwatch-nextword-muted {
  font-size: 20px;
  border: 3px solid Red;
}

.Stopwatch-buttons {
  width: 200px;
  padding: 10px 0;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.updownms-buttons {
  padding: 10px 0;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 20px;
}

.updownms-buttons button {
  font-size: 20px;
}
.Stopwatch-buttons button {
  background-color: #222222;
  border: solid 1px transparent;
  border-radius: 4px;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 16px;
  margin: 0 5px;
  cursor: pointer;
}

.Stopwatch-buttons button:hover {
  background-color: #999999;
}

.scroll-container {
  height: 300px;

  font-size: 20px;
  border: 1px solid grey;
  position: relative;
  margin-top: 10px;
  margin-bottom: 20px;
  overflow: scroll;
}

.Delay-display {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 30px;
}

.Delay-text {
  width: 300px;
}

.textField {
  margin: 20px;
  background-color: transparent;
}

.buttonDelay {
  background-color: #222222;
  border: solid 1px transparent;
  border-radius: 4px;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 16px;
  margin: 0 5px;
  cursor: pointer;
  width: 200px;
}

.element {
  margin: 10px;
  font-size: 15px;
}

.element.no-padding {
  padding-top: 0;
}

.active {
  color: red !important;
}

.Subtitle-buttons {
  width: 300px;
  padding: 10px 0;
  font-size: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Subtitle-buttons button {
  background-color: #222222;
  border: solid 1px transparent;
  border-radius: 4px;
  padding: 1px 10px;
  color: #ffffff;
  font-size: 16px;
  margin: 0 5px;
  cursor: pointer;
}

.Subtitle-buttons button:hover {
  background-color: #777777;
}

.Subtitle-buttons2 {
  width: 140px;
  padding: 10px 0;
  font-size: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.arrow-group {
  color: #222222;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.arrow-group p {
  font-size: 15px;
  margin: 0px;
  margin-left: 5px;
}
