.banner {
  background: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #444444;
  margin: 0px auto;
}

.banner p {
  width: 500px;
  margin: 5px auto;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
